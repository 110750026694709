"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.importTree = exports.getChildren = exports.getNode = exports.getParents = exports.getLevel = exports.getDepth = void 0;
function getDepth(root) {
    if (root === undefined) {
        return 0;
    }
    const fatherDepth = getDepth(root.father);
    const motherDepth = getDepth(root.mother);
    return Math.max(fatherDepth, motherDepth) + 1;
}
exports.getDepth = getDepth;
function getLevel(root, level) {
    if (root === undefined) {
        return [];
    }
    if (level === 0) {
        return [root];
    }
    return [...getLevel(root.father, level - 1), ...getLevel(root.mother, level - 1)];
}
exports.getLevel = getLevel;
function getParents(root) {
    if (root === undefined) {
        return [];
    }
    const father = root.father ? [root.father, ...getParents(root.father)] : [];
    const mother = root.mother ? [root.mother, ...getParents(root.mother)] : [];
    return [...father, ...mother];
}
exports.getParents = getParents;
function getNode(root, name) {
    if (root === undefined) {
        return undefined;
    }
    if (root.name === name) {
        return root;
    }
    return getNode(root.father, name) || getNode(root.mother, name);
}
exports.getNode = getNode;
function getChildren(root, subject, accumulator = []) {
    if (root === undefined) {
        return [];
    }
    if ([root.father, root.mother].includes(subject)) {
        return [root, ...accumulator];
    }
    return [
        ...getChildren(root.father, subject, [root, ...accumulator]),
        ...getChildren(root.mother, subject, [root, ...accumulator]),
    ];
}
exports.getChildren = getChildren;
function importTree(people) {
    const nameToPerson = new Map(people.map(person => [person.name, person]));
    if (people.length !== nameToPerson.size) {
        throw Error('Duplicate');
    }
    const parents = new Set([...people.flatMap(({ father, mother }) => [father, mother])]);
    const roots = people.filter(({ name }) => !parents.has(name));
    const [root, ...extra] = roots;
    if (!root) {
        throw Error('No root');
    }
    if (extra.length) {
        throw Error('Multiple roots');
    }
    return convertPerson(root, nameToPerson);
}
exports.importTree = importTree;
function convertPerson(root, nameToPerson) {
    const parentCount = [root.father, root.mother].filter(parent => parent).length;
    if (parentCount === 1) {
        throw Error('Single parent');
    }
    if (parentCount === 0) {
        return { ...root, father: undefined, mother: undefined };
    }
    if ([root.father, root.mother].some(parent => !nameToPerson.has(parent))) {
        throw Error('Parent not found');
    }
    const father = convertPerson(nameToPerson.get(root.father), nameToPerson);
    const mother = convertPerson(nameToPerson.get(root.mother), nameToPerson);
    return { ...root, father, mother };
}
