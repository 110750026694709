"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.load = void 0;
const encryption_1 = require("./encryption");
const tree_1 = require("./tree");
function personToRow(person, level, index) {
    const fatherOrMother = level === 0 ? '' : index % 2 === 0 ? 'father' : 'mother';
    const columns = [person.from || '??', person.born || '??', person.died || '??'];
    const name = `<span class="name">${person.name}</span>`;
    return `<td class=${fatherOrMother}>${name}</td><td>${columns.join('</td><td>')}</td>`;
}
function generateLevelRow(level, depth) {
    return `<td colspan="4" class="generation">Generation ${depth - level}</td>`;
}
function renderTree(data) {
    const root = (0, tree_1.importTree)(data);
    const depth = (0, tree_1.getDepth)(root);
    Array(depth)
        .fill(0)
        .forEach((_, level) => {
        const table = document.getElementById('main').children[0];
        const levelRow = table.insertRow(-1);
        levelRow.innerHTML = generateLevelRow(level, depth);
        const people = (0, tree_1.getLevel)(root, level);
        people.forEach((person, index) => {
            const personRow = table.insertRow(-1);
            personRow.innerHTML = personToRow(person, level, index);
            personRow.id = nameToId(person.name);
            personRow.children[0].children[0].onclick = () => highlight(root, personRow.id);
        });
    });
}
function highlight(root, subjectId) {
    const person = (0, tree_1.getNode)(root, idToName(subjectId));
    const parents = (0, tree_1.getParents)(person);
    const children = (0, tree_1.getChildren)(root, person);
    const highlightIds = [person, ...parents, ...children].map(person => nameToId(person.name));
    const ids = [root, ...(0, tree_1.getParents)(root)].map(person => nameToId(person.name));
    ids.forEach(id => document.getElementById(id)?.classList.remove('highlighted', 'selected'));
    document.getElementById(subjectId)?.classList.add('selected');
    highlightIds.forEach(id => document.getElementById(id)?.classList.add('highlighted'));
}
async function fetchEncryptedData() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const isDemo = urlSearchParams.get('demo') === 'true';
    const filename = isDemo ? 'demo-encrypted-data.txt' : 'encrypted-data.txt';
    return fetch(filename).then(response => response.text());
}
async function load() {
    const encryptedData = await fetchEncryptedData();
    const passwordBox = document.getElementById('password');
    const data = await decrypt(encryptedData, passwordBox.value);
    if (data) {
        const peopleRaw = JSON.parse(data);
        renderTree(peopleRaw);
        document.getElementById('password-form').remove();
        document.getElementById('main').classList.remove('hidden');
    }
    else {
        passwordBox.value = '';
        document.getElementById('password-message').classList.remove('hidden');
    }
}
exports.load = load;
async function decrypt(encryptedData, password) {
    try {
        return await (0, encryption_1.decryptData)(encryptedData, password);
    }
    catch (e) {
        console.error(e);
        return undefined;
    }
}
function nameToId(name) {
    return name.replaceAll(' ', '-');
}
function idToName(name) {
    return name.replaceAll('-', ' ');
}
exports.default = {
    load,
};
